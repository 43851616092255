import React from "react";
import ReactDOM from "react-dom";
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from "polotno";


import { Workspace } from "polotno/canvas/workspace";
import { SidePanel } from "polotno/side-panel";
import { Toolbar } from "polotno/toolbar/toolbar";
import { ZoomButtons } from "polotno/toolbar/zoom-buttons";
import { createStore } from "polotno/model/store";
import './App.css';
const store = createStore({
  // this is a demo key just for that project
  // (!) please don't use it in your projects
  // to create your own API key please go here: https://polotno.dev/cabinet
  key: "nFA5H9elEytDyPyvKL7T",
  // you can hide back-link on a paid license
  // but it will be good if you can keep it for Polotno project support
  showCredit: true
});
// add to global namespace for debugging
window.store = store;

// add page and element instantly
store.addPage();

function App() {
  return (
    <PolotnoContainer className="polotno-app-container">
      <SidePanelWrap>
        <h1 className="logo-pxl">PixlStudio</h1>
        <SidePanel store={store} />
      </SidePanelWrap>
      <WorkspaceWrap>
        <Toolbar store={store} />
        <Workspace store={store} />
        <ZoomButtons store={store} />
      </WorkspaceWrap>
    </PolotnoContainer>
  );
}

export default App;
